






















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  deleteUser,
  deleteUserBulk,
  exportUsers,
  getUsers,
  getUsersCount
} from '@/api/users'
import {
  CollectionRequestMeta,
  createFilter,
  dateFilter,
  getSortItems,
  prepareListFilters,
  rolesFilter, searchFieldConcat, searchFieldConcatReverse, simpleSearchField, switchStateFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  successMsg,
  errorMsg,
  confirmDialog
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'UserList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected = []
  private listQuery: CollectionRequestMeta = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime;
  private getTranslations = getTranslations
  private hasPermission = hasPermission
  private searchFields = [
    searchFieldConcat(),
    simpleSearchField('office.name'),
    simpleSearchField('office.code'),
    searchFieldConcatReverse(),
    {
      type: 'function',
      key: 'office.code,code',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    },
    { key: 'code' },
    { key: 'mainEmail' }
  ]

  private filters: any = [rolesFilter()]

  created() {
    this.getQueryParams()
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private getQueryParams() {
    const query: any = this.$route.query || {}
    this.filters.push(dateFilter({
      from: query.from ? new Date(Number(query.from)) : null,
      to: query.to ? new Date(Number(query.to)) : null
    }))
    this.filters.push(switchStateFilter({
      key: 'active',
      label: 'filters.activeUsers',
      modelValue: query.active === true || query.active === 'true'
    }))
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteUser({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private changePassword(id: string) {
    return this.$router.push('/user/change-password/' + id)
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/user/edit/' + id)
  }

  private goToUserStatistics(id: any) {
    return this.$router.push('/user/statistics/' + id)
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteUserBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: this.searchFields,
      filters: filters
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getUsersCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportUsers(this.list)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getUsers(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
